<template>
  <div class="story-bar" :id="`bar-${barId}`">
    <div :class="`story-bar-inner`" :id="`bar-in-${barInId}`"></div>
  </div>
</template>

<script>
export default {
  props: ["posts", "creator", "index"],
  data: function() {
    return {
      barId: this.$global.fastID(),
      barInId: this.$global.fastID(),
      duration: 0,
      animation: null,
    };
  },
  methods: {
    startAnimation: async function() {
      if (!this.barIn) {
        await this.sleep(10);
        return this.startAnimation();
      }

      this.animation = this.barIn.animate([{ width: "0" }, { width: "100%" }], {
        fill: "forwards",
        easing: "linear",
        duration: this.duration,
      });

      this.animation.onfinish = function() {
        this.$emit("onfinish", { index: this.index });
      }.bind(this);
    },
    play: function() {
      this.animation.play();
    },
    pause: function() {
      this.animation.pause();
    },
    start: function({ duration, index }) {
      this.$store.commit("setCurrentIndexStories", index);
      this.duration = duration;
      this.currentIndex = index;
      this.startAnimation();
    },
    finish: function() {
      this.animation.finish();
    },
    complete: function() {
      this.animation.currentTime = 999999999;
      this.animation.pause();
    },
    cancel: function() {
      if (!this.animation) return;
      this.animation.startTime = 0;
      this.animation.currentTime = 0;
      this.animation.pause();
    },
  },
  computed: {
    bar: function() {
      return document.getElementById(`bar-${this.barId}`);
    },
    barIn: function() {
      return document.getElementById(`bar-in-${this.barInId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.story-bar {
  height: 4px;
  background: rgba(255, 255, 255, 0.274);
  border-radius: 1pc;
  overflow: hidden;
  width: 100%;
  flex: 1;
  &-inner {
    height: 4px;
    background: #fff;
    width: 0;
    &.__ended {
      width: 100%;
    }
  }
}
</style>
